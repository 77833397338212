@font-face {
  font-family: Germania;
  src: url("GermaniaOne-Regular.cd8aa564.ttf");
}

@font-face {
  font-family: Teko;
  src: url("Teko-Medium.d16cbfb9.ttf");
}

* {
  font-family: Teko;
}

#infobar {
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  width: 1350px;
  height: 90px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#infobar.debug {
  background-color: #0f0;
}

#infobar-rows {
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  display: flex;
  position: absolute;
  top: 90px;
}

.infobar-row {
  color: #fff;
  align-items: center;
  height: 90px;
  margin-top: 0;
  padding-top: 0;
  font-size: 40px;
  display: flex;
  position: relative;
}

.infobar-row .hl {
  color: #82dcfa;
  text-transform: none;
  padding: 0 12px;
}

/*# sourceMappingURL=index.6d7919ed.css.map */
