@font-face {
    font-family: Germania;
    src: url(../fonts/GermaniaOne-Regular.ttf);
}

@font-face {
    font-family: Teko;
    src: url(../fonts/Teko-Medium.ttf);
}

* {
    font-family: Teko;
}

#infobar {
    position: absolute;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1350px;
    height: 90px;
    top: 0;
    left: 0;
    &.debug {
        background-color: lime;
    }
}

#infobar-rows {
    display: flex;
    width: 100%;
    max-width: 1350px;
    flex-flow: column;
    position: absolute;
    top: 90px;
    align-items: center;
}

.infobar-row {
    position: relative;
    height: 90px;
    font-size: 40px;

    color: white;
    margin-top: 0px;
    padding-top: 0;

    display: flex;
    align-items: center;
}

.infobar-row .hl {
    color: #82dcfa;
    text-transform: none;
    padding: 0 12px;
}